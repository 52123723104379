<template>
    <div>
        <v-card tile>
            <v-card-text>
                <v-container>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-select
                                :items="['All Users', 'Subscribed Users', 'Unsubscribed Users', 'Party Wise', 'State Wise']"
                                @onchange="onSendNotificationToChange" label="Send Notification To" outlined dense required
                                :error-messages="messageSendNotificationErrors"
                                @input="$v.editedItem.sendNotificationTo.$touch()"
                                @blur="$v.editedItem.sendNotificationTo.$touch()" v-model="editedItem.sendNotificationTo"
                                prepend-icon="" append-icon="mdi-access-point"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select v-show="editedItem.sendNotificationTo == 'Party Wise'" outlined :multiple="true" dense
                                :items="politicals" v-model="editedItem.political_party" required
                                @input="editedIndex == -1 ? $v.editedItem.political_party.$touch() : null"
                                @blur="editedIndex == -1 ? $v.editedItem.political_party.$touch() : null"
                                label="Political party" append-icon="mdi-gamepad-circle"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select outlined :multiple="true" dense :items="states" v-model="editedItem.state"
                                v-show="editedItem.sendNotificationTo == 'State Wise'" required
                                @input="editedIndex == -1 ? $v.editedItem.state.$touch() : null"
                                @blur="editedIndex == -1 ? $v.editedItem.state.$touch() : null" label="State"
                                append-icon="mdi-gamepad-circle"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-file-input label="Image" outlined dense v-model="editedItem.image" prepend-icon=""
                                @change="getImage" append-icon="mdi-image"></v-file-input>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea outlined dense v-model="editedItem.title" :error-messages="titleErrors" required
                                @input="$v.editedItem.title.$touch()" @blur="$v.editedItem.title.$touch()" label="Title"
                                append-icon="mdi-rename-box"></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea outlined dense v-model="editedItem.description" :error-messages="descriptionErrors"
                                required @input="$v.editedItem.description.$touch()"
                                @blur="$v.editedItem.description.$touch()" label="Message"
                                append-icon="mdi-rename-box"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-alert v-if="error" dense type="error">
                                {{ error }}
                            </v-alert>
                            <v-alert v-if="success" dense type="success">
                                {{ success }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-divider class="primary" />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large color="primary" @click="save">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
    name: 'Notification',
    mixins: [validationMixin],
    data: () => ({
        headers: [
            {
                text: 'Logo',
                value: 'party_logo',
                sortable: false,
                align: 'start'
            },
            { text: 'Name', value: 'party_name' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        data: [],
        apiUrl: process.env.VUE_APP_API_URL,
        loading: true,
        dialog: false,
        dialogDelete: false,
        error: '',
        politicals: [],
        states: [],
        success: '',
        selectedImage: null,
        editedIndex: -1,
        editedItem: {
            title: '',
            description: null,
            image: null,
            sendNotificationTo: null,
            political_party: [],
            state: [],
        },
        defaultItem: {
            title: '',
            description: '',
            image: null,
            political_party: [],
            state: [],
            sendNotificationTo: null,
        },
    }),
    created() {
        this.getAllPoliticals();
        this.getAllStates();
    },
    methods: {
        checkRequirement() {
            return true;
        },
        getImage() {
            let formData = new FormData();
            formData.append('img', this.editedItem.image);
            formData.append('source', "notification");
            this.$Axios({
                method: 'post',
                url: '/api/v1/util/upload-image',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then(({ data }) => {
                    this.selectedImage = data.img
                })
                .catch((e) => {
                    console.log(e)
                });
        },
        onSendNotificationToChange() {
            this.editedItem.playerIds = '';
            this.editedItem.users = [];
        },
        editItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editedItem.image = null
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.deletePolitical(this.editedItem.id)
            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$v.$reset()
            this.error = ''
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        save() {
            this.addNotification()
        },
        //Notification API Calls
        //Notification API Calls
        addNotification() {

            this.error = '';
            this.success = '';
            this.$v.$touch();
            console.log(this.$v.$error);
            if (this.$v.$error) {
                return;
            }

            let updateData = {};

            if (this.editedItem.sendNotificationTo == "All Users") {
                updateData.send_to = "all_users"
            }
            if (this.editedItem.sendNotificationTo == "Subscribed Users") {
                updateData.send_to = "subscribed_users"
            }
            if (this.editedItem.sendNotificationTo == "Unsubscribed Users") {
                updateData.send_to = "unsubscribed_users"
            }
            if (this.editedItem.sendNotificationTo == "Party Wise") {
                updateData.send_to = "political_party"
                updateData.political_party = this.editedItem.political_party
                // if the political_party option selected is "Select All" then send all the political parties
                if (this.editedItem.political_party.includes("Select All")) {
                    updateData.political_party = this.politicals.map((el) => {
                        return el.value;
                    });
                    updateData.political_party.shift();
                } else {
                    updateData.political_party = this.editedItem.political_party;
                }
            }
            if (this.editedItem.sendNotificationTo == "State Wise") {
                updateData.send_to = "state"
                if (this.editedItem.state.includes("Select All")) {
                    updateData.state = this.states.map((el) => {
                        return el.value;
                    });
                    updateData.state.shift();
                } else {
                    updateData.state = this.editedItem.state;
                }
            }

            updateData.title = this.editedItem.title
            updateData.description = this.editedItem.description
            if (this.selectedImage != null) {
                updateData.image = this.selectedImage
            }

            console.log('adding item : ', updateData);
            this.$Axios
                .post("/api/v1/notification/send/", updateData)
                .then((response) => {
                    // if status is 201
                    if (response.status === 201) {
                        this.editedItem = this.defaultItem
                        this.$v.$reset();
                        this.success = 'Notification Sent Successfully!';
                    } else {
                        this.error = 'Something went wrong please try again later';
                    }
                })
                .catch((e) => {
                    console.log(e.response?.data);
                    this.error = e.response?.data?.msg ?? 'Something Went wrong please try again later';
                });
        },
        getAllPoliticals() {
            this.$Axios
                .get('/api/v1/political/')
                .then((res) => {
                    this.politicals = res.data.map((el) => {
                        return { value: el.id, text: el.name }
                    })
                    this.politicals.unshift({ value: 'Select All', text: 'Select All' })
                })
                .catch((e) => {
                    console.log(e);
                })
        },
        getAllStates() {
            this.$Axios
                .get('/api/v1/state/')
                .then((res) => {
                    this.states = res.data.map((el) => {
                        return { value: el.id, text: el.name }
                    })
                    this.states.unshift({ value: 'Select All', text: 'Select All' })
                })
                .catch((e) => {
                    console.log(e);
                })
        },
    },
    computed: {
        //// Vuelidate Errors
        messageSendNotificationErrors() {
            const errors = []
            if (!this.$v.editedItem.sendNotificationTo.$dirty) return errors
            !this.$v.editedItem.sendNotificationTo.required && errors.push('Please select to whom notification will be sent!!')
            return errors
        },
        titleErrors() {
            const errors = []
            if (!this.$v.editedItem.title.$dirty) return errors
            !this.$v.editedItem.title.required && errors.push('Title is required !')
            return errors
        },
        descriptionErrors() {
            const errors = []
            if (!this.$v.editedItem.description.$dirty) return errors
            !this.$v.editedItem.description.required && errors.push('Description is required !')
            return errors
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    validations: {
        editedItem: {
            sendNotificationTo: {
                required
            },
            title: {
                required
            },
            description: {
                required
            },
        },
    },
};
</script>